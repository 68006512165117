import React, { useEffect, useRef } from "react";
import { useState } from "react";
import "./AlphaRegister.css";

const AlphaRegister = () => {
  const [tellUs, setTellUs] = useState(false);
  const [isParticipated, setisParticipated] = useState(false);
  const [isMailExists, setIsMailExists] = useState({
    isExists: false,
    mailMsg: "",
  });
  const [isDatabaseError, setIsDatabaseError] = useState({
    isError: false,
    errorMsg: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  //object that will be sent to database
  const [player, setPlayer] = useState({
    UserName: "",
    FirstName: "",
    LastName: "",
    Email: "",
    Referral: "",
    SteamID: "",
    DiscordID: "",
    QInterested: "",
    QTesting: "",
    TellUs: "",
    Titles: "",
  });

  // Ref to store the previous email value
  const prevEmailRef = useRef();

  useEffect(() => {
    if (!tellUs) {
      setPlayer((prevPlayer) => {
        return {
          ...prevPlayer,
          TellUs: "",
        };
      });
    }
  }, [tellUs]);

  useEffect(() => {
    // Check if the email has changed
    if (player.Email !== prevEmailRef.current) {
      setIsMailExists({ isExists: false, mailMsg: "" });
    }
  }, [player.Email]); // Depend on the player object to run whenever it changes

  //Save the data to state
  const handlePlayer = (event) => {
    setPlayer((prevPlayer) => {
      return {
        ...prevPlayer,
        [event.target.name]: event.target.value,
      };
    });
  };

  const submitToApi = async (player) => {
    if (player.TellUs) {
      player.Referral = player.TellUs;
    }
    const response = await fetch("/userregister", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(player),
    });
    if (!response.ok) {
      // If the response is not ok (not in the 200-299 range), throw an error
      const errorMessage = await response.json(); // Use.text() since the server responds with plain text
      // Check if the error message contains 'MailMessage' or 'DatabaseError'
      if (errorMessage.hasOwnProperty("mailMessage")) {
        console.log("Messages");
        // Handle the case where the error message is related to the email
        setIsMailExists({ isExists: true, mailMsg: errorMessage.mailMessage });
        setIsDatabaseError({ isError: false, errorMsg: "" });
      } else if (errorMessage.hasOwnProperty("databaseError")) {
        // Handle the case where the error message is related to the database
        // You might want to handle this differently, e.g., show a different message or take another action
        console.error("Database error:", errorMessage.databaseError);
        // Example: Show a generic error message to the user
        // Use msg from response
        setIsMailExists({ isExists: false, mailMsg: "" });
        setIsDatabaseError({isError:true,errorMsg:"We are having trouble submitting your form. Please try again later!"})
      }
    }
    else{
      setIsSubmitted(true);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    submitToApi(player);
  };

  return (
    <section className="AlphaRegister">
      {!isSubmitted ? (
        <>
          <h2>JOIN THE BETA</h2>
          <h4>Sign up for Strike Protocol Testing</h4>
          <h5>(PC only test)</h5>
          <div className="GetAlphaForm">
            <form className="AlphaRegForm" onSubmit={(e) => handleSubmit(e)}>
              <div className="ARdiv">
                <label htmlFor="UserNameAR">
                  UserName <span className="form_required">(Required)</span>{" "}
                </label>
                <input
                  id="UserNameAR"
                  name="UserName"
                  type="text"
                  value={player.UserName || ""}
                  required
                  onChange={handlePlayer}
                />
              </div>

              <div className="doubleAR">
                <div className="ARdiv half">
                  <label htmlFor="FirstNameAR">First name</label>
                  <input
                    id="FirstNameAR"
                    name="FirstName"
                    type="text"
                    value={player.FirstName}
                    onChange={handlePlayer}
                  />
                </div>

                <div className="ARdiv half">
                  <label htmlFor="LastNameAR">Last name</label>
                  <input
                    id="LastNameAR"
                    name="LastName"
                    type="text"
                    value={player.LastName}
                    onChange={handlePlayer}
                  />
                </div>
              </div>

              <div className="ARdiv">
                <label htmlFor="EmailAR">
                  Email address{" "}
                  <span className="form_required">(Required)</span>
                </label>
                <input
                  id="EmailAR"
                  name="Email"
                  type="email"
                  value={player.Email}
                  required
                  onChange={handlePlayer}
                />
                {isMailExists && isMailExists.isExists && (
                  <p className="form_required" style={{ fontSize: "15px" }}>
                    {isMailExists.mailMsg}
                  </p>
                )}
              </div>

              <div className="doubleAR">
                <div className="ARdiv half">
                  <label htmlFor="SteamAR">Steam ID</label>
                  <input
                    id="SteamAR"
                    name="SteamID"
                    type="text"
                    value={player.SteamID}
                    onChange={handlePlayer}
                  />
                </div>

                <div className="ARdiv half">
                  <label htmlFor="DiscordAR">Discord ID</label>
                  <input
                    id="DiscordAR"
                    name="DiscordID"
                    type="text"
                    value={player.DiscordID}
                    onChange={handlePlayer}
                  />
                </div>
              </div>

              <div className="ARdiv">
                <label htmlFor="FindUSAR">
                  How did you hear about us?{" "}
                  <span className="form_required"> (Required)</span>
                </label>
                <select
                  id="FindUSAR"
                  name="Referral"
                  value={player.Referral}
                  required
                  onChange={(e) => {
                    handlePlayer(e); // Handle the change to update player state
                    const value = e.target.value;
                    if (value !== "customAnswerReferral") {
                      setTellUs(false);
                    } else {
                      setTellUs(true);
                    }
                  }}
                >
                  <option value="">-- Choose --</option>
                  <option value="Facebook">Facebook</option>
                  <option value="Instagram">Instagram</option>
                  <option value="Telegram">Telegram</option>
                  <option value="TikTok">TikTok</option>
                  <option value="Twitter(X)">Twitter(X)</option>
                  <option value="Word of mouth">Word of mouth</option>
                  <option value="customAnswerReferral">
                    Other Where did you hear about Strike Protocol?
                  </option>
                </select>
              </div>
              {tellUs && (
                <div className="ARdiv">
                  <label htmlFor="TellUs">
                    Please tell us where did you hear about us?{" "}
                    <span className="form_required"> (Required)</span>
                  </label>
                  <input
                    id="TellUs"
                    name="TellUs"
                    type="text"
                    value={player.TellUs}
                    required
                    onChange={handlePlayer}
                  />
                </div>
              )}

              <div className="ARdiv">
                <label htmlFor="WhyAR">
                  Why are you interested in testing Strike Protocol?{" "}
                  <span className="form_required">(Required)</span>
                </label>
                <textarea
                  id="WhyAR"
                  name="QInterested"
                  value={player.QInterested}
                  required
                  onChange={handlePlayer}
                />
              </div>

              <div className="ARdiv">
                <p>
                  Have you ever participated in game testing?{" "}
                  <span className="form_required">(Required)</span>
                </p>
                <div className="radio-container">
                  <div className="radioq">
                    <input
                      id="TARYES"
                      type="radio"
                      name="QTesting"
                      value="1"
                      required
                      onChange={(e) => {
                        handlePlayer(e);
                        const value = e.target.value;
                        if (value === "1") setisParticipated(true);
                      }}
                    />
                    <label htmlFor="TARYES">YES</label>
                  </div>
                  <div className="radioq">
                    <input
                      id="TARNO"
                      type="radio"
                      name="QTesting"
                      value="0"
                      required
                      onChange={(e) => {
                        handlePlayer(e);
                        const value = e.target.value;
                        if (value === "0") setisParticipated(false);
                      }}
                    />
                    <label htmlFor="TARNO">NO</label>
                  </div>
                </div>
              </div>
              {isParticipated && (
                <div className="ARdiv" style={{ paddingTop: 0 }}>
                  <label htmlFor="Titles">
                    Titles of games you participated in testing
                    <span className="form_required"> (Required)</span>
                  </label>
                  <input
                    id="Titles"
                    name="Titles"
                    type="text"
                    value={player.Titles}
                    required
                    onChange={handlePlayer}
                  />
                </div>
              )}
                    {isDatabaseError && isDatabaseError.isError && (
                  <p className="form_required" style={{ fontSize: "15px" }}>
                    {isDatabaseError.errorMsg}
                  </p>
                )}
              <br />
              <button className="alpha-submit" type="submit">Register</button>
            </form>
          </div>
        </>
      ) : (
        <div className="modal">
          <div className="modal-content">
            <img
              src={require("../centered-no-text.png")}
              alt="Strike Protocol"
            />
            <h3>THANK YOU FOR SIGNING UP!</h3>
            <p>
              You've successfully signed up for the beta testing phase of our
              game. Thank you for joining us on this exciting journey! We'll be
              in touch soon with further instructions and updates. Chosen
              testers will be notified via the email you provided during
              sign-up, so keep an eye on your inbox.
            </p>
            <p>
              In the meantime, feel free to spread the word and stay tuned for
              sneak peeks and exclusive content.
            </p>
            <p className="note">
              *note: Once you activate your Steam key, the Strike Protocol will
              become visible on your Steam account for immediate access.
            </p>
            <h4>HAPPY GAMING!</h4>
            <button onClick={() => (window.location.href = "/")}>
              Back to Home
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default AlphaRegister;
