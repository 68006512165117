import React, { Component } from 'react';
import Hero from './Sections/Hero';
import TheProject from './Sections/TheProject';
import Playstyles from './Sections/Playstyles';

import FAQ from './Sections/FAQ';
import Contact from './Sections/Contact';
import Partners from './Sections/Partners';

export class Home extends Component {
    static displayName = Home.name;

    render() {
        return (
            <div>
                <Hero className="Hero" to='alpha-registration' />
                <TheProject className="TheProject" />
                <Playstyles className="Playstyles" />

                <FAQ className='FAQ' />
                <Contact className="Contact" />
                <Partners className="Partners" />
            </div>
        );
    }
}