import React from 'react';
import '../sectionsCss/Hero.css';

const Hero = ({to}) => {
  return (
    <section className='Hero'>
      <div>
          <img src={require('../../assets/herosection/no-bg1-3.png')} className='title' alt={'Strike Protocol'} />    
          </div>
          <h1 className="Main_title">Strike Protocol</h1>
          <div className='HeroButtonsDiv'>
              {/* --Remove watch trailer btn temporary
              <a href="https://www.youtube.com/watch?v=AMk0BSqDDIk&ab_channel=StrikeProtocol" target="_blank" rel="noopener noreferrer">
                  <button className='btn1'>
                      <img src={require('../../assets/herosection/carbon_play-filled.png')} alt={''} />
                      <p>
                          Watch Trailer
                      </p>
                      
                  </button> 
              </a>
              */}

              {/*
              <a href={`/${to}`}>
                  <button className='btn2'>
                      
                      <p>
                          Join the beta
                      </p>
                  </button>
              </a>

              <a href="https://store.steampowered.com/app/2938200/Strike_Protocol/" target="_blank" rel="noferrer noreferrer">
                  <button className='btn2'>
                      <img className="cta-hero" src={require('../../assets/herosection/steam.png')} alt={''} />
                  </button>
              </a>

              
              --TEMPORARY REMOVE
              <a href="https://store.steampowered.com/app/2938200/Strike_Protocol/" target="_blank" rel="noferrer noreferrer">
                  <button className='cta-btn'>
                      <img className="cta-hero" src={require('../../assets/herosection/steam.png')} alt={''} /> 
                      <p>Get via Steam</p>
                  </button>
              </a>

              <a href="https://store.epicgames.com/en-US/p/strike-protocol-c4d50b" target="_blank" rel="noferrer noreferrer">
                  <button className='cta-btn'>
                      <img className="cta-hero" src={require('../../assets/herosection/epic.png')} alt={''} />
                      <p>Get via Epic Games</p>
                  </button>
              </a>
              */}

              <div className="announcement-container">
                  <h3>ANNOUNCEMENT - The move to Web3</h3>
                  <p className="announcement-text">             
                  Dear Strike Protocol Community,<br></br>
                      The game is temporarily offline because we're transitioning it into the Web3 space. During this phase of<br></br>
                      development, the game needs to be republished to support all the new and exciting features coming<br></br>
                      down the pipeline. Unfortunately, this means it will be unavailable on Steam and temporarily unavailable<br></br>
                      on the Epic Games Store.
                      Our team is very excited about all the upcoming changes and is working very<br></br>
                      hard to bring you more immersive and engaging experiences with future content.<br></br>
                      <br></br>
                      Stay tuned on our socials for all newer updates and thank you for your patience during this period!<br></br>
                      <br></br>
                      See you back in the game soon!
                      <br></br><br></br>
                      The GD Entertainment Team</p>

                  <p className="announcement-text-mobile">
                      Dear Strike Protocol Community,<br></br>
                      The game is temporarily offline because we're transitioning it into the Web3 space. During this phase of
                      development, the game needs to be republished to support all the new and exciting features coming
                      down the pipeline. Unfortunately, this means it will be unavailable on Steam and temporarily unavailable
                      on the Epic Games Store.
                      Our team is very excited about all the upcoming changes and is working very
                      hard to bring you more immersive and engaging experiences with future content.
                      Stay tuned on our socials for all newer updates and thank you for your patience during this period!
                      <br></br><br></br>
                      See you back in the game soon!
                      <br></br><br></br>
                      The GD Entertainment Team</p>

              </div>

              <div className="cta-container">

                  <a href="https://github.com/solidproof/projects/blob/main/2024/GLOBAL%20DODO%20ENTERTAINMENT/KYC_Certificate_GD_ENTERTAINMENT.png" target="_blank" rel="noferrer noreferrer">
                      <button className='investor_btn'>
                          <p>
                              KYC
                          </p>
                      </button>
                  </a>


                  <a href="https://app.uniswap.org/swap?inputCurrency=ETH&outputCurrency=0x1c74d8c8fe37b0fa47debc82e0ab6925a3dc4a2f" target="_blank" rel="noferrer noreferrer" >
                      <button className='investor_btn'>
                          <p>
                              Uniswap
                          </p>
                      </button>
                  </a>

                  <a href="https://www.dextools.io/app/en/ether/pair-explorer/0x233b82f36d64c25db0a17344d8eb618809548fff?t=1730833350088" target="_blank" rel="noferrer noreferrer" >
                      <button className='investor_btn'>
                          <p>
                              Dextools
                          </p>
                      </button>
                  </a>
              </div>
      </div>  
      </section>

  )
}

export default Hero