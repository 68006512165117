
const CookiePolicy = () => {
    

    return (
        <section className="PrivacyPolicy">
            <div className="policies">
                <h2>Cookie Policy</h2>
                <p>
                    Welcome to Global Dodo Entertainment's Cookie Policy. This document explains how we use cookies and similar technologies on our website.<br></br>

                    <br></br><br></br>

                    <b>Company Information</b><br></br>
                    Company Name: Global Dodo Entertainment<br></br>
                    VAT-ID: HR23258956590<br></br>
                    Headquarters: Štoosova 12, HR-10000 Zagreb, Croatia<br></br>
                    Email: info@gd-entertainment.hr<br></br>

                    <br></br><br></br>

                    <b>What Are Cookies?</b><br></br>
                    Cookies are small text files stored on your device by your web browser. They are widely used to make websites work more efficiently and to provide information to the site owners.<br></br>

                    <br></br><br></br>

                    <b>How We Use Cookies</b><br></br><br></br>
                    <b>We use cookies to</b>:<br></br>

                    Improve the functionality of our website.<br></br>
                    Understand how you use our site.<br></br>
                    Enhance your user experience.<br></br>
                    Remember your preferences and settings.<br></br>
                    Provide relevant content and advertisements.<br></br>
                    Types of Cookies We Use<br></br>
                    Necessary Cookies: These cookies are essential for the website to function properly. They ensure basic functionalities and security features of the website.<br></br>

                    <br></br><br></br>

                    <b>Performance Cookies</b>: These cookies help us understand and analyze how you use the website, allowing us to improve its performance. They collect information such as the number of visitors, which pages are visited, and the sources of the traffic.<br></br>

                    <br></br><br></br>

                    <b>Functional Cookies</b>: These cookies allow the website to remember your preferences and choices, such as your language or region, to provide a more personalized experience.<br></br><br></br>

                    <b>Advertising Cookies</b>: These cookies are used to deliver relevant advertisements to you. They also help us measure the effectiveness of our advertising campaigns.<br></br>

                    <br></br><br></br>

                    <b>Managing Cookies</b><br></br>
                    You can manage your cookie preferences through your browser settings. Most web browsers allow you to control cookies through their settings. However, disabling cookies may affect your experience on our website.<br></br>

                    <br></br><br></br>

                    Google Chrome: Manage Cookies<br></br>
                    Mozilla Firefox: Manage Cookies<br></br>
                    Safari: Manage Cookies<br></br>
                    Microsoft Edge: Manage Cookies<br></br>
                    Changes to Our Cookie Policy<br></br>

                    <br></br>

                    We may update this Cookie Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. Please revisit this page periodically to stay informed about our use of cookies.<br></br>

                    <br></br><br></br>

                    <b>Contact Us</b><br></br>
                    If you have any questions about our Cookie Policy, please contact us at:<br></br>
                    <br></br>

                    <b>Email</b>: info@gd-entertainment.hr<br></br>
                    <b>Address</b>: Štoosova 12, HR-10000 Zagreb, Croatia
                    
                    <br></br><br></br>

                    By using our website, you agree to our use of cookies as described in this Cookie Policy. Thank you for visiting Global Dodo Entertainment!
                </p>
            </div>
        </section>
    );
};

export default CookiePolicy;
