
const PrivacyPolicy = () => {
    

    return (
        <section className="PrivacyPolicy">
            <div className="policies">
                <h2>Privacy Policy</h2>
                <p>
                Welcome to the Privacy Policy of Global Dodo Entertainment. This policy outlines how we collect, use, disclose, and protect your personal information when you interact with our services. Your privacy is important to us, and we are committed to safeguarding your data.
                    <br></br><br></br>
                    <b>Company Information</b>:<br></br>
                    Company Name: Global Dodo Entertainment<br></br>
                    VAT-ID: HR23258956590<br></br>
                    Headquarters: Štoosova 12, HR-10000 Zagreb, Croatia<br></br>
                    Email: info@gd-entertainment.hr<br></br>

                    <br></br><br></br>

                    <b>Information We Collect</b><br></br>
                    Personal Information<br></br>

                    <br></br>

                    <b>We collect personal information that you provide to us, such as</b>:<br></br>
                    Name<br></br>
                    Email address<br></br>
                    Phone number<br></br>
                    Address<br></br>
                    Payment information<br></br>
                    Usage Data<br></br>

                    <br></br><br></br>

                    <b>We automatically collect information about your interactions with our services, including</b>:<br></br>
                    IP address<br></br>
                    Browser type and version<br></br>
                    Device information<br></br>
                    Pages visited<br></br>
                    Time and date of visits<br></br>
                    Referring website addresses<br></br>
                    How We Use Your Information<br></br>

                    <br></br><br></br>

                    <b>We use your personal information to</b>:<br></br>
                    Provide, operate, and maintain our services<br></br>
                    Improve, personalize, and expand our services<br></br>
                    Understand and analyze how you use our services<br></br>
                    Develop new products, services, features, and functionality<br></br>
                    Process transactions and manage your orders<br></br>
                    Communicate with you, including customer support<br></br>
                    Send you updates, promotions, and marketing materials<br></br>
                    Prevent fraud and ensure the security of our services<br></br>
                    Sharing Your Information<br></br>

                    <br></br><br></br>

                    <b>We do not sell or rent your personal information to third parties. However, we may share your information with</b>:<br></br>
                    Service Providers: We may share your information with third-party vendors who perform services on our behalf, such as payment processing, data analysis, email delivery, hosting services, and customer service.
                    Business Transfers: In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of that transaction.
                    Legal Requirements: We may disclose your information if required to do so by law or in response to valid requests by public authorities.
                    Data Security
                    We take reasonable measures to protect your personal information from loss, theft, misuse, unauthorized access, disclosure, alteration, and destruction. However, no security measure is perfect, and we cannot guarantee absolute security.

                    <br></br><br></br>

                    <b>Your Rights</b><br></br>
                    <b>You have the right to</b>:<br></br>
                    Access the personal information we hold about you<br></br>
                    Request the correction of inaccurate or incomplete information<br></br>
                    Request the deletion of your personal information<br></br>
                    Object to the processing of your personal information<br></br>
                    Request the restriction of processing your personal information<br></br>
                    Request the transfer of your personal information to another organization<br></br>
                    To exercise these rights, please contact us at info@gd-entertainment.hr.<br></br>

                    <br></br><br></br>

                    <b>Cookies and Tracking Technologies</b><br></br>
                    We use cookies and similar tracking technologies to track the activity on our services and hold certain information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some parts of our services.

                    <br></br><br></br>

                    <b>Changes to This Privacy Policy</b><br></br>
                    We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.

                    <br></br><br></br>

                    <b>Contact Us</b><br></br>
                    If you have any questions about this Privacy Policy, please contact us:

                    <br></br><br></br>

                    <b>Email</b>: info@gd-entertainment.hr<br></br>
                    <b>Address</b>: Štoosova 12, HR-10000 Zagreb, Croatia

                    <br></br><br></br>

                    Thank you for trusting Global Dodo Entertainment with your personal information. We are committed to protecting your privacy and ensuring the security of your data.<br></br>
                </p>
            </div>
        </section>
    );
};

export default PrivacyPolicy;
