import React, { useState } from "react";
import "../sectionsCss/Contact.css";

const Contact = () => {
  const [isDatabaseError, setIsDatabaseError] = useState({
    isError: false,
    errorMsg: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  //object that will be sent to database
  const [contactData, setContactData] = useState({
    FirstName: "",
    Email: "",
    Question: "",
  });
  const handleContactData = (event) => {
    setContactData((prevContactData) => {
      return {
        ...prevContactData,
        [event.target.name]: event.target.value,
      };
    });
  };

  const submitToApi = async (contactData) => {
    const response = await fetch("/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(contactData),
    });

    if (!response.ok) {
      setIsSubmitted(false);
      const errorMessage = await response.json(); // Use.text() since the server responds with plain text
      if (errorMessage.hasOwnProperty("databaseError")) {
        setIsDatabaseError({
          isError: true,
          errorMsg:
            "We are having trouble submitting your form. Please try again later!",
        });
      }
    } else {
      setIsSubmitted(true);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    submitToApi(contactData);
  };

  return (
    <section className="Contact" id="Contact">
      <h2>Contact us</h2>
      <h4>Have any questions?<br></br> Feel free to Contact us.</h4>
      <div className="GetContactForm">
        <form
          className="AlphaRegForm ContactContainer"
          onSubmit={(e) => handleSubmit(e)}
        >
          <div>
            <label htmlFor="FirstNameAR">First name <span className="form_required">(Required)</span>{" "}</label>
            <input
              id="FirstNameAR"
              name="FirstName"
              type="text"
              value={contactData.FirstName}
              required
              onChange={(e) => {
                handleContactData(e);
              }}
            />
          </div>

          <div>
            <label htmlFor="EmailAR">Email address <span className="form_required">(Required)</span>{" "}</label>          
            <input
              id="EmailAR"
              name="Email"
              type="email"
              value={contactData.Email}
              required
              onChange={(e) => {
                handleContactData(e);
              }}
            />
          </div>

          <div>
            <label htmlFor="WhyAR">
              Want to know more about the game? Let us know.
            </label>
            <textarea
              id="WhyAR"
              name="Question"
              value={contactData.QInterested}
              required
              onChange={(e) => {
                handleContactData(e);
              }}
            />
          </div>

          <div>
            <button type="submit">Contact us</button>
          </div>
          {isDatabaseError && isDatabaseError.isError && (
            <div
              className="ARdiv form_required"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                padding: "20px",
              }}
            >
              <div style={{ width: "100%" }}> {isDatabaseError.errorMsg}</div>
            </div>
          )}
          {isSubmitted && (
            <div
              className="ARdiv contact-good-resp"
              style={{
                color: "rgb(70, 170, 117)",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                padding: "20px",
              }}
            >
              <div style={{ width: "100%" }}>
                Thank you for contact us. We'll contact you as soon as posible!
              </div>
            </div>
          )}
        </form>
      </div>
    </section>
  );
};

export default Contact;
