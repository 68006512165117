import React from 'react';
import '../sectionsCss/NavModule.css'; 
import { getDomainUrl } from '../../utils/getDomainUrl'; 

const NavModule = ({ isOpen, closeMenu }) => {

    const mainDomain = getDomainUrl();

    return (
        <div className={`NavModule ${isOpen ? 'NavModuleShow' : 'NavModuleHide'}`}>
            <div className='exitBtnMenuDiv'>
                <button onClick={closeMenu}>
                    <img src={require('../../assets/Nav/ic_baseline-navigate-next.png')} alt='' />
                </button>
            </div>
           
            <div className='Menu Mobile'>
                <a href={`${mainDomain}#TheProject`}>About</a>
                <a href={`${mainDomain}#playStyles`}>Playstyles</a>
                <a href={`${mainDomain}#FAQs`}>FAQs</a>
                <a href={`${mainDomain}#Contact`}>Contact</a>
                <a href={`${mainDomain}#Partners`}>Partners</a>
                <a href={`${mainDomain}/alpha-registration`} >Join Beta</a>
            </div>
        </div>
    );
};

export default NavModule;
