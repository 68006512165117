import React from 'react';
import { getDomainUrl } from '../utils/getDomainUrl'; 

const NotFound = () => {

    const mainDomain = getDomainUrl();

    return (
        <section className="not-found">
            <div className="not-found-container">
                <div>
                    <h2>404 - Page Not Found</h2>
                    <p>Sorry, the page you are looking for does not exist.</p>
                </div>

                <div className='contactDiv'>
                    <a href={`${mainDomain}`}>
                        <button className='home-btn'>
                            Go to Home Page
                        </button>
                    </a>
                </div>
            </div>
        </section>
    );
};

export default NotFound;