import React, { Component } from 'react';
import { Container } from 'reactstrap';
import Nav from './Sections/Nav';
import Footer from './Sections/Footer'; 

export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
        <div className="App">

        <Nav to='alpha-registration' />

        {/* <NavMenu /> */}

        <Container tag="main">
          {this.props.children}
        </Container>
   
        <Footer className="Footer" /> 
            
      </div>
    );
  }
}
