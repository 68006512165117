import React, { useState } from "react";
import "../sectionsCss/Footer.css";
import { getDomainUrl } from '../../utils/getDomainUrl'; 

const socialMedia = [
    {
        icon: "faceboook-st.png",
        href: 'https://www.facebook.com/people/Strike-Protocol/61556624401398/'
    },
    {
        icon: "youtube-st.png",
        href: 'https://www.youtube.com/@StrikeProtocol'
    },
    {
        icon: "twitter-st.png",
        href: 'https://twitter.com/StrikeProtocol1'
    },
    {
        icon: "discord-st.png",
        href: 'https://discord.gg/SHaJwBESC4'
    },

];

const Footer = () => {
  const [user, setUser] = useState({ Email: "" });
  const [isMailExists, setIsMailExists] = useState({
    isExists: false,
    mailMsg: "",
  });
  const [isDatabaseError, setIsDatabaseError] = useState({
    isError: false,
    errorMsg: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const handleUser = (event) => {
    setUser((prevUser) => ({
      ...prevUser,
      [event.target.name]: event.target.value,
    }));
  };

  console.log(user);

  const submitUser = (event) => {
    event.preventDefault();
    submitToApi(user);
  };

  const submitToApi = async (user) => {
    try {
      //const BASE_URL = process.env.NODE_ENV === 'production' ? 'https://yourproductionurl.com' : 'https://localhost:44401';
      const endpoint = `/newsletter`;
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      });

      if (!response.ok) {
        // If the response is not ok (not in the 200-299 range), throw an error
        const errorMessage = await response.json(); // Use.text() since the server responds with plain text
        console.log(errorMessage);
        // Check if the error message contains 'MailMessage' or 'DatabaseError'
        if (errorMessage.hasOwnProperty("mailMessage")) {
          // Handle the case where the error message is related to the email
          setIsMailExists({
            isExists: true,
            mailMsg: errorMessage.mailMessage,
          });
          setIsDatabaseError({ isError: false, errorMsg: "" });
          setIsSubmitted(false)
        } else if (errorMessage.hasOwnProperty("databaseError")) {
          // Handle the case where the error message is related to the database
          // You might want to handle this differently, e.g., show a different message or take another action
          console.error("Database error:", errorMessage.databaseError);
          // Example: Show a generic error message to the user
          // Use msg from response
          setIsMailExists({ isExists: false, mailMsg: "" });
          setIsSubmitted(false)
          setIsDatabaseError({
            isError: true,
            errorMsg:
              "We are having trouble submitting your form. Please try again later!",
          });

        }
      } else {
        setIsSubmitted(true);
        setIsMailExists({ isExists: false, mailMsg: "" });
        setIsDatabaseError({ isError: false, errorMsg: "" });
      
      }

      const data = await response.json();
      console.log("Data submitted successfully:", data);
    } catch (error) {
      console.error("Error submitting data:", error);

      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      } else if (error.request) {
        console.error("Request data:", error.request);
      } else {
        console.error("Error message:", error.message);
      }
      console.error("Error config:", error.config);
    }
    };

    const mainDomain = getDomainUrl();

  return (
    <footer className="footer">
      <h4>SIGN UP FOR EMAIL UPDATES</h4>
      <div className="email-signup">
        <form onSubmit={submitUser}>
          <input
            type="email"
            name="Email"
            value={user.Email}
            onChange={handleUser}
            placeholder="Your Email Address"
          />

          <button type="submit">Sign Up</button>
        </form>
      </div>
      {isMailExists && isMailExists.isExists && (
        <div
          className="ARdiv form_required"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            padding: "20px",
          }}
        >
          <div style={{ width: "50%" }}> {isMailExists.mailMsg}</div>
        </div>
      )}
      {isDatabaseError && isDatabaseError.isError && (
        <div
          className="ARdiv form_required"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            padding: "20px",
          }}
        >
          <div style={{ width: "50%" }}> {isDatabaseError.errorMsg}</div>
        </div>
      )}
      {isSubmitted && (
        <div
          className="ARdiv"
          style={{
            color: "rgb(70, 170, 117)",
            width: "90%",
            display: "flex",
            justifyContent: "center",
            padding: "20px",
          }}
        >
          <div className="newsletter-good-resp">
            Thank you for subscribing to our newsletter You will now receive
            updates, news, and special offers straight to your inbox. We're
            excited to have you join our community!
          </div>
        </div>
      )}

      <div className="social-media">
        <h4>FOLLOW US ON SOCIAL MEDIA</h4>
        <div className="SMiconDiv">
          {socialMedia.map((socMed) => (
            <div key={socMed.icon} className="SocialMediaIcon">
              <a href={socMed.href} target="_blank" rel="noreferrer">
                <img
                  src={require(`../../assets/Footer/${socMed.icon}`)}
                  className="socialMediaIcon"
                  alt={socMed.icon}
                />
              </a>
            </div>
          ))}
        </div>
      </div>

      <div className="footer-bottom">
        <p className="copyright">
          Copyright @ 2024{" "}
          <a
            href={"https://gd-entertainment.hr/"}
            target="_blank"
            rel="noreferrer"
          >
            GD Entertainment
          </a>{" "}
                  - All Rights Reserved.
                  <br>
                  </br>
                  <div className="privacy-container">
                  <a className="policy_link" href={`${mainDomain}/privacy-policy`}>Privacy Policy</a>
                  <a className="policy_link" href={`${mainDomain}/terms-and-conditions`}>Terms and Conditions</a>
                  <a className="policy_link" href={`${mainDomain}/cookie-policy`}>Cookie Policy</a>
                  </div>
        </p>
        <p className="developed-by">
          Developed by{" "}
          <a href={"https://protogenos.hr"} target="_blank" rel="noreferrer">
            Protogenos
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
