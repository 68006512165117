import React, { useState, useEffect } from 'react';
import '../App.css';
import { getDomainUrl } from '../utils/getDomainUrl'; 


const CookieConsent = () => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const consent = getCookie('userConsent');
        if (!consent) {
            setVisible(true);
        }
    }, []);

    const handleAcceptAll = () => {
        setCookie('userConsent', 'accepted', 30); // 30 days
        setVisible(false);
    };

    const handleAcceptNecessary = () => {
        setCookie('userConsent', 'necessary', 30); // 30 days
        setVisible(false);
    };

    const setCookie = (name, value, days) => {
        const expires = new Date();
        expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
        document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
    };

    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
        return null;
    };

    if (!visible) return null;

    const mainDomain = getDomainUrl();

    return (
        <div className="consent-bar">
            <p className="consent-text">
                We use cookies to improve your experience on our site. By using our site, you consent to cookies.
                Visit our <a className="privacy-link" href={`${mainDomain}/privacy-policy`}>Privacy policy</a> page for more info.
            </p>
            <div>
                <button className="consent-button" onClick={handleAcceptAll}>Accept All</button>
                <button className="consent-button-secondary" onClick={handleAcceptNecessary}>Accept Necessary</button>
            </div>
        </div>
    );
};

export default CookieConsent;
