
const TermsConditions = () => {


    return (
        <section className="TermsConditions">
            <div className="policies">
                <h2>Terms and Conditions</h2>
                <p>
                    Welcome to the Terms and Conditions ("Terms") of Global Dodo Entertainment. These Terms govern your use of our website and services. By accessing or using our services, you agree to comply with and be bound by these Terms. If you do not agree with any part of these Terms, please do not use our services.<br></br>

                    <br></br><br></br>

                    <b>Company Information</b><br></br>
                    Company Name: Global Dodo Entertainment<br></br>
                    VAT-ID: HR23258956590<br></br>
                    Headquarters: Štoosova 12, HR-10000 Zagreb, Croatia<br></br>
                    Email: info@gd-entertainment.hr<br></br>

                    <br></br><br></br>

                    <b>Use of Services</b><br></br>
                    Eligibility<br></br>
                    You must be at least 18 years old to use our services. By using our services, you represent and warrant that you meet this age requirement.<br></br>

                    <br></br><br></br>

                    <b>Account Registration</b><br></br>
                    To access certain features of our services, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete. You are responsible for safeguarding your account information and for any activities or actions under your account.<br></br>

                    <br></br><br></br>

                    <b>User Obligations</b><br></br>
                    Prohibited Activities<br></br>

                    <br></br><br></br>

                    <b>You agree not to</b>:<br></br>
                    Violate any applicable laws or regulations<br></br>
                    Engage in any fraudulent or deceptive practices<br></br>
                    Infringe upon the intellectual property rights of others<br></br>
                    Transmit any harmful or malicious code<br></br>
                    Interfere with the operation of our services<br></br>
                    Use our services for any unlawful or unauthorized purpose<br></br>
                    Intellectual Property<br></br>
                    All content and materials on our website and services, including text, graphics, logos, and software, are the property of Global Dodo Entertainment or its licensors and are protected by intellectual property laws. You are granted a limited, non-exclusive, non-transferable, and revocable license to access and use our services for personal, non-commercial use.<br></br>

                    <br></br><br></br>

                    <b>Payment and Refunds</b><br></br>
                    Pricing<br></br>
                    All prices for our products and services are listed on our website and are subject to change without notice.<br></br>

                    <br></br><br></br>

                    <b>Payments</b><br></br>
                    Payments for our products and services can be made using the payment methods specified on our website. You agree to provide accurate and complete payment information and to promptly update your account information with any changes.<br></br>

                    <br></br><br></br>

                    <b>Refunds</b><br></br>
                    Our refund policy is outlined on our website. If you are not satisfied with a product or service, please contact us at info@gd-entertainment.hr for assistance.<br></br>

                    <br></br><br></br>

                    <b>Limitation of Liability</b><br></br>
                    To the fullest extent permitted by law, Global Dodo Entertainment shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, use, goodwill, or other intangible losses, resulting from:<br></br>
                    <br></br>
                    Your use of or inability to use our services<br></br>
                    Any unauthorized access to or use of our servers and/or any personal information stored therein<br></br>
                    Any interruption or cessation of transmission to or from our services<br></br>
                    Any bugs, viruses, or harmful code that may be transmitted to or through our services by any third party<br></br>
                    Any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through our services<br></br>
                    Indemnification<br></br>
                    You agree to indemnify and hold harmless Global Dodo Entertainment and its officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees, arising out of or in any way connected with your access to or use of our services, your violation of these Terms, or your infringement of any intellectual property or other rights of any person or entity.<br></br>

                    <br></br><br></br>

                    <b>Governing Law</b><br></br>
                    These Terms shall be governed and construed in accordance with the laws of Croatia, without regard to its conflict of law provisions. Any disputes arising from or related to these Terms or our services shall be subject to the exclusive jurisdiction of the courts located in Zagreb, Croatia.<br></br>

                    <br></br><br></br>

                    <b>Changes to These Terms</b><br></br>
                    We reserve the right to modify or replace these Terms at any time. We will notify you of any changes by posting the new Terms on our website. You are advised to review these Terms periodically for any changes. Your continued use of our services after the posting of changes constitutes your acceptance of the revised Terms.<br></br>

                    <br></br><br></br>

                    <b>Contact Us</b><br></br>
                    If you have any questions about these Terms, please contact us:<br></br>

                    <br></br><br></br>

                    <b>Email</b>: info@gd-entertainment.hr<br></br>
                    <b>Address</b>: Štoosova 12, HR-10000 Zagreb, Croatia<br></br>

                    <br></br><br></br>

                    Thank you for using Global Dodo Entertainment's services. We are committed to providing you with the best experience possible.
                </p>
            </div>
        </section>
    );
};

export default TermsConditions;